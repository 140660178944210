

.light-purple {
    // background: url(https://a.cdnsbn.com/images/common/2016footer.jpg);
     background-repeat: repeat;
    height: 250px;
    background-color:  #060047;
  }
  
  .footer-center {
    width: 80%;
    margin: auto;
  }
  
  .footer-center {
    display: grid;
    grid-template-columns: auto auto auto;
  }
  
  /* LEFT BOX */
  
  .footer-email {
    width: 70%;
    height: 40px;
    font-size: 16px;
    border-radius: 15px;
  
  }
  .footer-name{
    margin-top: 10px;
    width: 70%;
    height: 40px;
    border-radius: 15px;
  }
  
  .footer-middlebox-margin > p,
  h3 {
    margin: 0px;
    padding: 0px;
  }
  
  .footer-ion-social > ion-icon {
    color: white;
    font-size: 32px;
  }
  .text-uppercase{
    color: white;
  }
  
  .footer-social {
    margin: 20px 0px;
    display: grid;
    grid-template-columns: 40px 40px 40px 40px 40px;
    grid-gap: 15px;
  }
  .footer-middlebox {
    display: grid;
    grid-template-columns: auto auto;
    margin: 30px 0px;
  }
  
  .footer-heart + div {
    color: white;
  }
  
  .footer-heart > ion-icon {
    font-size: 72px;
    color: white;
  }

  .footer-lastbox {
    display: grid;
    grid-template-columns: auto auto;
    margin: 30px 0px;
  }
  
  .footer-plane > ion-icon {
    font-size: 72px;
    color: white;
  }
  
  .footer-plane + div {
    color: white;
    font-size: 25px;
  }  
  .purple {
    background-color: #52246d;
  }
  
  .foot-center {
    width: 80%;
    margin: auto;
  }
  
  .ship > div {
    border-right: 1px solid #ee2c72;
    padding: 5px 20px;
    width: fit-content;
  }
  
  .ship {
    display: flex;
    color: white;
  }
  
  .return {
    padding: 20px;
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 30px;
    color: white;
    
  }
  
  .with > img {
    width: 100%;
  }
  
  .with {
    display: grid;
    grid-template-columns: repeat(3, 60px);
    grid-gap: 9px;
  }
  
  .download {
    display: flex;
    flex-direction: column;
    color: white;
  }
  
  .download > img {
    width: 100px;
    margin: 20px 0px;
  }
  
  .excellent > img {
    width: 100%;
  }
  
  .excellent {
    display: grid;
    grid-template-columns: repeat(2, 120px);
    grid-gap: 20px;
  }
  
  .pay {
    color: white;
    margin: 35px;
    margin-left: 0px;
    display: grid;
    grid-template-columns: repeat(5, auto);
    border-bottom: 5px solid #ee2c72;
  }
  
  .my-account > p,
  .our-company > p {
    margin: 15px 0px;
  }
  
  .my-account > p:hover,
  .our-company > p:hover {
    color: #a788be;
  }
  
  .end-part,
  .end {
    color: white;
  }
  
  .end-part > p {
    border-right: 1px solid white;
    padding: 0px 10px;
  }
  
  .end-part > p:hover {
    color: #a788be;
  }
  
  .end-part {
    display: flex;
  }
  
  .end {
    padding: 0px 10px;
    font-size: 15px;
    padding-bottom: 40px;
  }
  .btn{
    //margin-top: 25px;
    // width: 50px;
    // height: 50px;
    // color: white;
    // border: 1px solid red;
    // background-color: #e7e7e7;
  }

  @media (min-width: 768px) {
    #home3footer{
      margin-left: 100px;
    }
  }
  
  /* SECOND BOX? */
  
  /* FOOTER WHOLE PART */

  #show-code-button-h3{

  padding: 5px;

  }


#show-code-button-h3 {
  --border: 5px;    /* the border width */
  --slant: 0em;   /* control the slanted corners */
  --color:  #3c4fe0; /* the color */
  
  font-size: 20px;
  border-radius: 10px;
  padding: 0.4em 1.2em;
  border: none;
  cursor: pointer;
  font-weight: bold;
  color: var(--color);
  background: 
     linear-gradient(to bottom left,var(--color)  50%,#0000 50.1%) top right,
     linear-gradient(to top   right,var(--color)  50%,#0000 50.1%) bottom left;
  background-size: calc(var(--slant) + 1.3*var(--border)) calc(var(--slant) + 1.3*var(--border));
  background-repeat: no-repeat;
  box-shadow:
    0 0 0 200px inset var(--s,#0000),
    0 0 0 var(--border) inset var(--color);
  clip-path: 
      polygon(0 0, calc(100% - var(--slant)) 0, 100% var(--slant),
              100% 100%, var(--slant) 100%,0 calc(100% - var(--slant))
             );
  transition: color var(--t,0.3s), background-size 0.3s;
}
#show-code-button-h3:focus-visible {
  outline-offset: calc(-1*var(--border));
  outline: var(--border) solid #000c;
  clip-path: none;
  background-size: 0 0;
}
#show-code-button-h3:hover,
button:active{
  background-size: 100% 100%;
  color: #fff;
  --t: 0.2s 0.1s;
}
#show-code-button-h3:active {
  --s: #0005;
  transition: none;
}

  