body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body::-webkit-scrollbar {
  width: 10px;
}

.grecaptcha-badge {
  visibility: hidden;
}

.show-recaptcha-badge .grecaptcha-badge {
  visibility: visible;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px white;
}

body::-webkit-scrollbar-thumb {
  background-color: rgb(126, 211, 33);
  outline: 1px solid rgb(126, 211, 33);
  border-radius: 5px;
  cursor: pointer;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.text-success {
  color: black;
  background-color: aquamarine;
}

.custom-pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0px;
  flex-wrap: wrap;
}

.custom-pagination .btn-green:active,
.custom-pagination .btn-green:focus {
  box-shadow: none !important;
  color: #ffffff;
}
.btn-green {
  background-color: #7ed321 !important;
  color: #ffffff !important;
}

.custom-pagination button {
  border: 1px solid #cdcdcd !important;
  border-radius: none;
}
.custom-pagination button:first-child {
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}
.custom-pagination button:last-child {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}
.custom-pagination button:not(:first-child) {
  border-left: none !important;
}

div.custom-pagination-pages {
  display: flex;
  justify-content: flex-end;
}
.nav-link:focus,
.nav-link:hover {
  color: #de001d !important;
}
.dropdown-item.active,
.dropdown-item:active {
  background-color: #de001d !important;
}

@media screen and (max-width: 600px) {
  div.custom-pagination-pages {
    padding: 1rem 0 0 0;
    /* margin-left: 17px; */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .custom-pagination {
    margin: 20px;
    text-align: center;
    justify-content: center;
  }
}

.dropdown-arrow {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

#dropdown-button-drop-sm {
  color: black !important;
  background-color: "#ffffff" !important;
}
