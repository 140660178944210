.footer {
  display: block;
  box-sizing: border-box;
  min-height: 200px;
  width: 100%;
  clip-path: ellipse(147% 100% at 89.77% 100%);
  padding: 4rem 2rem 2rem 2rem;

  h6,
  p {
    color: white;
  }
  a {
    text-decoration: none;
    color: inherit;
  }
  a:hover {
    color: inherit;
    transition: 400ms;
    font-size: 17px;
  }
}

@media only screen and (max-width: 768px) {
  .footer {
    clip-path: none;
    padding: 1rem;
  }
}