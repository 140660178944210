.header {
  background-color: #fff;

  a {
    text-decoration: none;
    color: inherit;
  }
}

#category-dropdown {
  color: rgba(0, 0, 0, 0.55);
  text-decoration: none;
}

#category-dropdown:focus,
a.dropdown-item:focus,
category-dropdown-item:focus,
category-dropdown-item:link,
category-dropdown-item:visited {
  color: white !important;
  text-decoration: none !important;
}
#collasible-nav-dropdown:focus,
#collasible-nav-dropdown:hover,
#collasible-nav-dropdown:active {
  color: rgba(0, 0, 0, 0.55) !important;
  text-decoration: none !important;
}

// for the searchBar

@media only screen and (min-width: 768px) {
  #searchBar {
    width: 50%;
    margin-left: 8%;
    margin-right: 5%;
  }
}
@media only screen and (max-width: 768px) {
#d-home-mt{
  padding-left: 5vw;
}
  #searchBar {
    width: 55%;
  }
  .navbar-brand{
  margin: 0 !important;
  padding: 0 !important;
  }
}
